import { createUseStyles } from 'react-jss';
import React from 'react';
import Card from '../components/Card';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const useStyles = createUseStyles((theme) => ({
  main: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  header: {
    textAlign: 'center',
  },
  link: {
    border: '1px solid black',
    borderRadius: '10px',
    color: 'black',
    fontSize: 'xx-large',
    fontWeight: 'bold',
    margin: '1rem 0',
    padding: '1.5rem',
    textDecoration: 'none',
    width: 'fit-content',
  },
}));

const styleFeatureCard = {
  alignItems: 'center',
  display: 'flex',
  flex: '1 1 320px',
  flexDirection: 'column',
  minWidth: '320px',
  textAlign: 'center',
  width: '90%',
};

const Apps = (props) => {
  const classes = useStyles(props);
  return (
    <Layout>
      <SEO title="Apps" />
      <h1 className={classes.header}>
        KounterTop Apps Download
      </h1>
      <div className={classes.main}>
        <Card style={styleFeatureCard}>
          Below are companion apps for the KounterTop Platform.
          <br />
          The apps below will not work on their own without an existing KounterTop account.
          <br />
          <a className={classes.link} href="https://play.google.com/store/apps/details?id=com.kountertop.kountertopdevice" style={{ backgroundColor: '#66BB6A' }}>
            Install App for Android
          </a>
          <a className={classes.link} href="https://play.google.com/apps/testing/com.kountertop.kountertopdevice" style={{ backgroundColor: '#8d6e63' }}>
            Install Beta App for Android
          </a>
          <a className={classes.link} href="https://play.google.com/store/apps/details?id=com.kountertop.omnikey" style={{ backgroundColor: '#29b6f6' }}>
            Install RFID App for Android
          </a>
          <a className={classes.link} href="https://device.kounter.top" style={{ backgroundColor: '#AB47BC' }}>
            KounterTop Device - Web / Apple
          </a>
          <a className={classes.link} href="https://device.beta.kounter.top">
            KounterTop Device (Beta) - Web / Apple
          </a>
        </Card>
      </div>
    </Layout>
  );
};

export default Apps;
